import { ThemeConfig } from 'tailwindcss/types/config'

const borderRadius = {
  ['none']: '0',
  ['xs']: '0.125rem',
  ['sm']: '0.25rem',
  ['md']: '0.5rem',
  ['lg']: '0.75rem',
  ['xl']: '1.25rem',
  ['2xl']: '2.5rem',
  ['3xl']: '3.75rem',
  ['full']: '50vh'
} satisfies ThemeConfig['borderRadius']

export { borderRadius }
