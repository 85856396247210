import defaultTheme from 'tailwindcss/defaultTheme'
import { ThemeConfig } from 'tailwindcss/types/config'

const zIndex = {
  auto: 'auto',
  0: '0',
  10: '10',
  20: '20',
  30: '30',
  40: '40',
  50: '50',
  100: '100'
} satisfies ThemeConfig['zIndex']

export { zIndex }
