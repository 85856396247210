import { ThemeConfig } from 'tailwindcss/types/config'

const animation = {
  none: 'none',
  spin: 'spin 1s linear infinite',
  ping: 'ping 1s cubic-bezier(0, 0, 0.2, 1) infinite',
  pulse: 'pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite',
  bounce: 'bounce 1s infinite',
  scaleIn: 'scaleIn 200ms ease',
  scaleOut: 'scaleOut 200ms ease',
  fadeIn: 'fadeIn 200ms ease',
  fadeOut: 'fadeOut 200ms ease',
  enterFromLeft: 'enterFromLeft 250ms ease',
  enterFromRight: 'enterFromRight 250ms ease',
  exitToLeft: 'exitToLeft 250ms ease',
  exitToRight: 'exitToRight 250ms ease',
  entering: 'slide 200ms',
  exiting: 'slide 200ms reverse ease-in',
  hide: 'hide 100ms ease-in',
  slideIn: 'slideIn 150ms cubic-bezier(0.16, 1, 0.3, 1)',
  swipeOut: 'swipeOut 100ms ease-out'
} satisfies ThemeConfig['animation']

const keyframes = {
  shimmer: {
    '100%': {
      transform: 'translateX(100%)'
    }
  },
  spin: {
    to: {
      transform: 'rotate(360deg)'
    }
  },
  ping: {
    '75%, 100%': {
      transform: 'scale(2)',
      opacity: '0'
    }
  },
  pulse: {
    '50%': {
      opacity: '.5'
    }
  },
  bounce: {
    '0%, 100%': {
      transform: 'translateY(-25%)',
      animationTimingFunction: 'cubic-bezier(0.8,0,1,1)'
    },
    '50%': {
      transform: 'none',
      animationTimingFunction: 'cubic-bezier(0,0,0.2,1)'
    }
  },
  enterFromRight: {
    from: { opacity: '0', transform: 'translateX(200px)' },
    to: { opacity: '1', transform: 'translateX(0)' }
  },
  enterFromLeft: {
    from: { opacity: '0', transform: 'translateX(-200px)' },
    to: { opacity: '1', transform: 'translateX(0)' }
  },
  exitToRight: {
    from: { opacity: '1', transform: 'translateX(0)' },
    to: { opacity: '0', transform: 'translateX(200px)' }
  },
  exitToLeft: {
    from: { opacity: '1', transform: 'translateX(0)' },
    to: { opacity: '0', transform: 'translateX(-200px)' }
  },
  scaleIn: {
    from: { opacity: '0', transform: 'rotateX(-10deg) scale(0.9)' },
    to: { opacity: '1', transform: 'rotateX(0deg) scale(1)' }
  },
  scaleOut: {
    from: { opacity: '1', transform: 'rotateX(0deg) scale(1)' },
    to: { opacity: '0', transform: 'rotateX(-10deg) scale(0.95)' }
  },
  fadeIn: {
    from: { opacity: '0' },
    to: { opacity: '1' }
  },
  fadeOut: {
    from: { opacity: '1' },
    to: { opacity: '0' }
  },
  slide: {
    from: {
      transform: 'var(--origin)',
      opacity: '0'
    },
    to: {
      transform: 'translateY(0)',
      opacity: '1'
    }
  },
  slideIn: {
    from: { transform: 'translateX(calc(100% + var(--viewport-padding)))' },
    to: { transform: 'translateX(0)' }
  },
  swipeOut: {
    from: { transform: 'translateX(var(--radix-toast-swipe-end-x))' },
    to: { transform: 'translateX(calc(100% + var(--viewport-padding)))' }
  },
  hide: {
    from: { opacity: '1' },
    to: { opacity: '0' }
  }
} satisfies ThemeConfig['keyframes']

export { animation, keyframes }
