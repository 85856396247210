import ArrowRight from './arrow-right.svg'
import Brand from './brand.svg'
import Camera from './camera.svg'
import ChevronDown from './chevron-down.svg'
import ChevronLeft from './chevron-left.svg'
import ChevronRight from './chevron-right.svg'
import ChevronUp from './chevron-up.svg'
import Close from './close.svg'
import Download from './download.svg'
import Phone from './phone.svg'
import Play from './play.svg'
import Share from './share.svg'
import SocialFacebook from './social-facebook.svg'
import SocialInstagram from './social-instagram.svg'
import SocialLinkedin from './social-linkedin.svg'
import SocialTelegram from './social-telegram.svg'
import SocialThreads from './social-threads.svg'
import SocialTiktok from './social-tiktok.svg'
import SocialTwitter from './social-twitter.svg'
import SocialWhatsapp from './social-whatsapp.svg'
import SocialYoutube from './social-youtube.svg'

const svg = {
  'arrow-right': ArrowRight,
  'brand': Brand,
  'camera': Camera,
  'chevron-down': ChevronDown,
  'chevron-left': ChevronLeft,
  'chevron-right': ChevronRight,
  'chevron-up': ChevronUp,
  'close': Close,
  'download': Download,
  'phone': Phone,
  'play': Play,
  'share': Share,
  'social-facebook': SocialFacebook,
  'social-instagram': SocialInstagram,
  'social-linkedin': SocialLinkedin,
  'social-telegram': SocialTelegram,
  'social-threads': SocialThreads,
  'social-tiktok': SocialTiktok,
  'social-twitter': SocialTwitter,
  'social-whatsapp': SocialWhatsapp,
  'social-youtube': SocialYoutube,
}

export type SvgName = keyof typeof svg

export { svg }