const colors = {
  // link colors
  link: {
    dark: 'text-gray-700 data-[hovered=true]:text-pink-500',
    light: 'text-white data-[hovered=true]:text-pink-500',
    primary: 'text-pink-500 data-[hovered=true]:text-purple',
    whatsapp: 'text-whatsapp-500 data-[hovered=true]:text-whatsapp-600'
  },
  // solid colors
  solid: {
    dark: 'border-gray-800 data-[hovered=true]:border-gray-900 bg-gray-800 data-[hovered=true]:bg-gray-900 text-white',
    light:
      'border-white text-gray-900 data-[hovered=true]:border-pink-500 bg-white data-[hovered=true]:bg-pink-500 data-[hovered=true]:text-white',
    primary:
      'border-pink-500 data-[hovered=true]:border-pink-500/80 bg-pink-500 data-[hovered=true]:bg-pink-500/80 text-white',
    whatsapp:
      'border-whatsapp-500 data-[hovered=true]:border-whatsapp-600 bg-whatsapp-500 data-[hovered=true]:bg-whatsapp-600 text-white'
  },
  // outline colors
  outline: {
    dark: 'border-gray-800 data-[hovered=true]:bg-gray-800 text-gray-800 data-[hovered=true]:text-white',
    light:
      'border-white data-[hovered=true]:bg-white text-white data-[hovered=true]:text-gray-800',
    primary:
      'border-gray-200 data-[hovered=true]:border-purple-500 data-[hovered=true]:bg-purple-500 data-[hovered=true]:text-white',
    whatsapp:
      'border-whatsapp-500 data-[hovered=true]:bg-whatsapp-500 text-whatsapp-500 data-[hovered=true]:text-white'
  },
  // ghost colors
  ghost: {
    dark: 'data-[hovered=true]:border-gray-50 data-[hovered=true]:bg-gray-50 text-gray-800',
    light:
      'data-[hovered=true]:border-gray-800/5 data-[hovered=true]:bg-gray-800/5 text-white data-[hovered=true]:text-gray-800',
    primary:
      'data-[hovered=true]:border-pink-500/5 data-[hovered=true]:bg-pink-500/5 text-pink-500',
    whatsapp:
      'data-[hovered=true]:border-whatsapp-500/5 data-[hovered=true]:bg-whatsapp-500/5 text-whatsapp-500'
  }
}

export default colors
