import { tv, VariantProps } from '@/utils/tv'
import colors from './button.colors'

export const button = tv({
  slots: {
    base: [
      'inline-flex',
      'items-center',
      'box-border',
      'appearance-none',
      'select-none',
      'whitespace-nowrap',
      'subpixel-antialiased',
      'overflow-hidden',
      'tap-highlight-transparent',
      'transition-[color,background-color,border-color,transform] duration-300',
      'outline-none',
      'data-[disabled=true]:opacity-70',
      'data-[disabled=true]:cursor-not-allowed'
    ],
    icon: ['outline-none', 'shrink-0']
  },
  variants: {
    variant: {
      link: {},
      solid: {},
      outline: {},
      ghost: {}
    },
    size: {
      xs: {
        base: 'text-button-sm gap-x-2',
        icon: 'w-3 h-3'
      },
      sm: {
        base: 'text-button-sm gap-x-2',
        icon: 'w-3 h-3'
      },
      md: {
        base: 'text-button-md gap-x-2',
        icon: 'w-4 h-4'
      },
      lg: {
        base: 'text-button-lg gap-x-2',
        icon: 'w-5 h-5'
      }
    },
    color: {
      dark: {
        base: 'data-[focus-visible=true]:outline-gray-400/30'
      },
      light: {
        base: 'data-[focus-visible=true]:outline-white/30'
      },
      primary: {
        base: 'data-[focus-visible=true]:outline-blue-500/30'
      },
      whatsapp: {
        base: 'data-[focus-visible=true]:outline-whatsapp-800/30'
      }
    },
    weight: {
      regular: 'font-regular',
      medium: 'font-medium',
      semibold: 'font-semibold',
      bold: 'font-bold'
    },
    fullWidth: {
      true: 'w-full',
      xxs: 'w-full xs:w-auto',
      xs: 'w-full sm:w-auto',
      sm: 'w-full md:w-auto',
      md: 'w-full lg:w-auto',
      lg: 'w-full xl:w-auto',
      mobile: 'w-full desktop:w-auto'
    },
    rounded: {
      true: ''
    },
    isIconOnly: {
      true: 'flex justify-center'
    },
    isBetween: {
      true: 'w-full justify-between'
    },
    uppercase: {
      true: 'uppercase'
    }
  },
  defaultVariants: {
    variant: 'link',
    size: 'md',
    color: 'dark',
    weight: 'medium',
    rounded: false,
    hoverScale: false,
    fullWidth: false,
    uppercase: false
  },
  compoundVariants: [
    // button
    {
      variant: ['solid', 'outline', 'ghost'],
      class: {
        base: [
          'tracking-wide',
          'border border-transparent',
          'data-[pressed=true]:scale-[0.98]',
          'data-[focus-visible=true]:outline-offset-0',
          'data-[focus-visible=true]:outline-3'
        ]
      }
    },

    // link
    {
      variant: ['link'],
      class: {
        base: [
          'data-[focus-visible=true]:outline-dotted',
          'data-[focus-visible=true]:outline-offset-6'
        ]
      }
    },

    // link / color
    {
      variant: 'link',
      color: 'dark',
      class: {
        base: colors.link.dark
      }
    },
    {
      variant: 'link',
      color: 'light',
      class: {
        base: colors.link.light
      }
    },
    {
      variant: 'link',
      color: 'primary',
      class: {
        base: colors.link.primary
      }
    },
    {
      variant: 'link',
      color: 'whatsapp',
      class: {
        base: colors.link.whatsapp
      }
    },

    // solid / color
    {
      variant: 'solid',
      color: 'dark',
      class: {
        base: colors.solid.dark
      }
    },
    {
      variant: 'solid',
      color: 'light',
      class: {
        base: colors.solid.light
      }
    },
    {
      variant: 'solid',
      color: 'primary',
      class: {
        base: colors.solid.primary
      }
    },
    {
      variant: 'solid',
      color: 'whatsapp',
      class: {
        base: colors.solid.whatsapp
      }
    },

    // outline / color
    {
      variant: 'outline',
      color: 'dark',
      class: {
        base: colors.outline.dark
      }
    },
    {
      variant: 'outline',
      color: 'light',
      class: {
        base: colors.outline.light
      }
    },
    {
      variant: 'outline',
      color: 'primary',
      class: {
        base: colors.outline.primary
      }
    },
    {
      variant: 'outline',
      color: 'whatsapp',
      class: {
        base: colors.outline.whatsapp
      }
    },

    // ghost / color
    {
      variant: 'ghost',
      color: 'dark',
      class: {
        base: colors.ghost.dark
      }
    },
    {
      variant: 'ghost',
      color: 'light',
      class: {
        base: colors.ghost.light
      }
    },
    {
      variant: 'ghost',
      color: 'primary',
      class: {
        base: colors.ghost.primary
      }
    },
    {
      variant: 'ghost',
      color: 'whatsapp',
      class: {
        base: colors.ghost.whatsapp
      }
    },

    // button / size
    {
      variant: ['solid', 'outline', 'ghost'],
      size: 'xs',
      class: {
        base: 'px-2.5 h-10'
      }
    },
    {
      variant: ['solid', 'outline', 'ghost'],
      size: 'sm',
      class: {
        base: 'px-3 h-12'
      }
    },
    {
      variant: ['solid', 'outline', 'ghost'],
      size: 'md',
      class: {
        base: 'px-5 h-14'
      }
    },
    {
      variant: ['solid', 'outline', 'ghost'],
      size: 'lg',
      class: {
        base: 'px-6 h-16'
      }
    },

    // isIconOnly
    {
      variant: ['solid', 'outline', 'ghost'],
      isIconOnly: true,
      size: 'xs',
      class: {
        base: 'w-10 p-0'
      }
    },
    {
      variant: ['solid', 'outline', 'ghost'],
      isIconOnly: true,
      size: 'sm',
      class: {
        base: 'w-12 p-0'
      }
    },
    {
      variant: ['solid', 'outline', 'ghost'],
      isIconOnly: true,
      size: 'md',
      class: {
        base: 'w-14 p-0'
      }
    },
    {
      variant: ['solid', 'outline', 'ghost'],
      isIconOnly: true,
      size: 'lg',
      class: {
        base: 'w-16 p-0'
      }
    },

    // rounded
    {
      variant: ['solid', 'outline', 'ghost'],
      rounded: true,
      class: {
        base: 'rounded-full'
      }
    },

    // full-width
    {
      fullWidth: [true, 'xxs', 'xs', 'sm', 'md', 'lg', 'mobile'],
      className: {
        base: 'text-center justify-center'
      }
    }
  ]
})

export type ButtonVariantProps = Omit<
  VariantProps<typeof button>,
  'isIconOnly' | 'isDisabled'
>
