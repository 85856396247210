'use client'

import { svg, SvgName } from '@/svg'
import { SVGProps } from 'react'

export type VectorProps = SVGProps<SVGElement> & {
  className?: string
  name: SvgName
}

const Vector = ({ className = '', name, ...rest }: VectorProps) => {
  const Component = svg[name]

  if (!Component) {
    return null
  }

  return (
    <Component
      {...rest}
      className={`${className} shrink-0 select-none outline-none`}
      aria-hidden="true"
      focusable="false"
      tabIndex="-1"
    />
  )
}

export default Vector
