import { ThemeConfig } from 'tailwindcss/types/config'

const outlineOffset = {
  0: '0px',
  1: '1px',
  2: '2px',
  3: '3px',
  4: '4px',
  5: '5px',
  6: '6px',
  7: '7px',
  8: '8px'
} satisfies ThemeConfig['outlineOffset']

const outlineWidth = {
  0: '0px',
  1: '1px',
  2: '2px',
  3: '3px',
  4: '4px',
  5: '5px',
  6: '6px',
  7: '7px',
  8: '8px'
} satisfies ThemeConfig['outlineWidth']

export { outlineOffset, outlineWidth }
