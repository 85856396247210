const colors = {
  transparent: 'transparent',
  white: '#FFFFFf',
  black: '#181E25',
  gray: {
    25: '#EEEFF2',
    50: '#EDEDED',
    100: '#E7EAF0',
    200: '#CFD6DF',
    300: '#ACB8C4',
    400: '#94A2B2',
    500: '#67788E',
    600: '#495A6E',
    700: '#384656',
    800: '#29333D',
    900: '#181E25'
  },
  pink: {
    500: '#E50157'
  },
  purple: {
    500: '#1B1C38'
  },
  red: {
    '50': '#fef2f2',
    '100': '#fee2e2',
    '200': '#fecaca',
    '300': '#fca5a5',
    '400': '#f87171',
    '500': '#ef4444',
    '600': '#dc2626',
    '700': '#b91c1c',
    '800': '#991b1b',
    '900': '#7f1d1d'
  },
  whatsapp: {
    300: '#dcf8c6',
    500: '#25D366',
    600: '#22C057',
    800: '#128c7e'
  }
}

export { colors }
