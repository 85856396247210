import { ComponentProps, Ref, useMemo } from 'react'
import { usePathname } from 'next/navigation'
import NextLinkProps from 'next/link'
import { AriaButtonProps } from '@react-aria/button'
import { SvgDetails } from '@/types/icon'
import { ButtonVariantProps, button } from '@/theme/components/button'
import { getFirstSegmentOfPathname } from '@/utils/url'

export type useLinkProps = Omit<ComponentProps<typeof NextLinkProps>, 'href'> &
  Omit<AriaButtonProps, 'className' | 'href'> &
  ButtonVariantProps & {
    ref?: Ref<HTMLAnchorElement>
    href: string
    className?: string
    activeClassName?: string
    title?: string
    startIcon?: SvgDetails
    startIconClassName?: string
    endIcon?: SvgDetails
    endIconClassName?: string
  }

export const useLink = (props: useLinkProps) => {
  const {
    ref: linkRef,
    className = '',
    activeClassName = '',
    children,
    href,
    startIcon,
    startIconClassName = '',
    endIcon,
    endIconClassName = '',
    variant = 'link',
    size = 'md',
    color = 'dark',
    weight = 'medium',
    rounded = false,
    fullWidth = false,
    uppercase = false,
    ...otherProps
  } = props

  const isIconOnly = !children && (!!startIcon || !!endIcon)

  const pathname = getFirstSegmentOfPathname(usePathname())
  const isActive = pathname === getFirstSegmentOfPathname(href)

  const styles = useMemo(() => {
    return button({
      variant,
      size,
      color,
      weight,
      rounded,
      fullWidth,
      isIconOnly,
      uppercase
    })
  }, [variant, size, color, weight, rounded, fullWidth, isIconOnly, uppercase])

  return {
    linkRef,
    className,
    activeClassName,
    children,
    styles,
    startIcon,
    startIconClassName,
    endIcon,
    endIconClassName,
    isActive,
    componentProps: {
      href,
      ...otherProps
    }
  }
}
